
import React, { useState, useEffect } from 'react'
import { getDatabase, ref, onValue } from '@firebase/database'
import { initializeApp } from '@firebase/app'

let loader = require('../images/loader.gif')



const firebaseConfig = {

    apiKey: "AIzaSyB-l1ZVTB2xX7b2db4l9o92iNNa3AYTVtQ",

    authDomain: "hptu-plus.firebaseapp.com",

    databaseURL: "https://hptu-plus-default-rtdb.firebaseio.com",

    projectId: "hptu-plus",

    storageBucket: "hptu-plus.appspot.com",

    messagingSenderId: "209940602863",

    appId: "1:209940602863:web:f6db860f36dbadc5db601a",

    measurementId: "G-HH3YP4DWRF",

    databaseURL: "https://hptu-plus-default-rtdb.firebaseio.com/"

};



export default function Result() {

    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const dbRef = ref(database, "result");


    const [search, setSearch] = useState('');


    const [array, setArray] = useState([]);



    let urlTemplate = "http://results.indiaresults.com/hp/himtu/result3/query.aspx?id=";








    useEffect(() => {
        onValue(dbRef, async (snapshot) => {
            const data = snapshot.val();

            setArray(Object.values(data).reverse())
        })
    }, [])




    if (array.length > 0) {
        return (
            <>

                    <nav className="m-3">
                        <div className="container-fluid">
                            <form className="d-flex" role="search">
                                <input className="form-control me-2 border-primary" placeholder="Search" onChange={(e) => setSearch(e.target.value.toLowerCase())} />
                            </form>
                        </div>
                    </nav>



                    {array.filter((result) => {
                        return search.toLowerCase() === '' ? result : result.title.toLowerCase().includes(search);
                    }).map((result) => (
                        <div className='m-3' key={result.id}>
                            <b>
                                {result.date}
                            </b>
                            <a className='btn border shadow p-3 mb-3 bg-orange text-white' onClick={() => { openWebContent(urlTemplate + result.id) }}>
                                <b>
                                    {result.title}
                                </b>
                            </a>
                        </div>
                    ))}


            </>
        )
    } else {
        return (
            <center>

                <img src={loader} width="50%" height="50%" ></img>


                <h3>We are just Providing you result from Official Sites</h3>

                <h4>You can also open official site from Result page <b>Open in Browser</b> Button at top right corner.</h4>
            
                <b>We are just providing you publically available information, we don't have any access to that information.</b>
                
            </center>

        )
    }


}












function openWebContent(url) {
    if (url != null) {
        window.Android.openWebContent(url)
    }
}



