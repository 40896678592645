import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

import { Carousel } from 'react-bootstrap';
import React from 'react'
import { Link } from 'react-router-dom'

import banner01 from '../images/banner01.png'

export default function Index() {
  return (
    <div>

      <ImageView />

      <h2 className="text-center text-white my-4 bg-orange p-2 border rounded">
        <b>
          Student Zone
        </b>
      </h2>



      <div class="row mt-2">


        <a class="col-4 btn text-primary" onClick={() => { openWebContent("https://www.himtu.ac.in/examination-time-table/") }} >
          <div class="p-3 border rounded bg-white shadow text-center h6">
            <TodayOutlinedIcon />
            <br />
            <b>
              New Datesheet
            </b>

          </div>
        </a>


        <a class="col-4 btn text-primary" onClick={()=>{openWebContent("http://himachal-pradesh.indiaresults.com/himtu/default.aspx")}}>
          <div class="p-3 border rounded bg-white shadow text-center h6">
            <StarBorderOutlinedIcon />
            <br />
            <b>
              Latest Result
            </b>
          </div>
        </a>


        <a class="col-4 btn text-primary" onClick={() => { openWebContent("https://hptu.iqwing.in/instructions") }} >
          <div class="p-3 border rounded bg-white shadow text-center h6">
            <RateReviewOutlinedIcon />
            <br />
            <b>Exam Forms</b>
          </div>
        </a>

      </div>

      <div class="row mt-2">

        <a class="col-4 btn text-primary" onClick={() => { openWebContent("https://hptu.iqwing.in/instructions") }} >
          <div class="p-3 border rounded bg-white shadow text-center h6">
            <RateReviewOutlinedIcon />
            <br />
            <b>
              Admit Card
            </b>
          </div>
        </a>

      </div>

    </div>
  )
}





function ImageView() {
  return (
    <>
      <Carousel className='m-1 border border-warning border-2 p-2 mb-4'>
        <Carousel.Item>
          <img src={banner01} className="d-block w-100" alt="..." height="200px" />
        </Carousel.Item>
      </Carousel>
    </>
  )

}



function openWebContent(url) {
  if (url != null) {
    window.Android.openWebContent(url);
  }
}