import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Result from './pages/Result';
import ExamForms from './pages/ExamForms';
import Index from './pages/Index';

function App() {
  return (
    <>
    
    <div className='container'>

    <BrowserRouter>
    <Routes>


      <Route path='/' element={<Index />} />
      <Route path='/hptu-result' element={<Result />} /> 
      <Route path='/exam-forms' element={<ExamForms />} />

      
    </Routes>
    
    </BrowserRouter>
    </div>
    </>
  );
}


export default App;
